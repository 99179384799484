import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import {
  ProductFeatureCellComponent,
  ProductPlatformCellComponent,
} from '@mkp/product/feature-product-shared';
import {
  CALENDAR7_FEATURE_COMPARE_TABLE_TRANSLATION,
  Product,
  ProductOfferingBenefit,
  ProductOfferingFeature,
  ProductOfferingFeatureCell,
  featureMapping,
  CALENDAR7_JOBUP_FEATURE_COMPARE_TABLE_TRANSLATION,
} from '@mkp/shared/util-product';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mkp-product-comparison-table-features',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    ProductPlatformCellComponent,
    ProductFeatureCellComponent,
    TranslateModule,
  ],
  templateUrl: './product-comparison-table-features.component.html',
  styleUrl: './product-comparison-table-features.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComparisonTableFeaturesComponent implements OnChanges {
  @Input() products!: Product[];
  @Input() feature!: ProductOfferingFeature;

  featureMappings: ProductOfferingFeatureCell[] = [];
  translation: string | undefined;

  ngOnChanges() {
    if (this.products?.length && this.feature) {
      this.translation = featureMapping[this.feature].title;

      this.featureMappings = this.products.map((product) => this.getFeatureMapping(product));
    }
  }

  getFeatureMapping(product: Product): ProductOfferingFeatureCell {
    const productFeatureId = product.features.find(
      ({ parent, id }) => id === this.feature.toString() || parent === this.feature.toString()
    )?.id as ProductOfferingFeature;

    const { parent: id = this.feature, translation = undefined } =
      featureMapping[productFeatureId ?? this.feature];

    return {
      id,
      value: !!productFeatureId,
      translation: this.getTranslation(productFeatureId, translation),
    } as ProductOfferingFeatureCell;
  }
  getTranslation(featureId?: string, translation?: string): string | undefined {
    // UX wants a different translation in the comparison table for the calender feature (7 days online) - XS product
    switch (featureId) {
      case ProductOfferingBenefit.Calendar7Jobs:
        return CALENDAR7_FEATURE_COMPARE_TABLE_TRANSLATION;
      case ProductOfferingBenefit.Calendar7Jobup:
        return CALENDAR7_JOBUP_FEATURE_COMPARE_TABLE_TRANSLATION;
      default:
        return translation;
    }
  }
}
