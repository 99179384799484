import { Injectable } from '@angular/core';
import { State } from '@app/store';
import { LanguageService } from '@core/services/language.service';
import { LanguageMapper } from '@mkp/shared/data-access';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { appHeaderActions, appInitFactoryActions, languageEffectsActions } from '@store/actions';
import { userApiActions } from '@user/store/actions';
import { selectLoggedInUser } from '@user/store/selectors/user.selectors';
import { ZendeskService } from 'libs/zendesk/util/src';
import { concatMap, map, takeUntil, tap } from 'rxjs/operators';
import { authActions, authApiActions } from '@mkp/auth/actions';

@Injectable()
export class LanguageEffects {
  readonly appLanguageInitialized$ = this.actions$.pipe(
    ofType(
      languageEffectsActions.appLanguageInitForUser,
      languageEffectsActions.appLanguageInitForVisitor
    )
  );

  readonly initAppLanguageForUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appInitFactoryActions.enter),
      // wait for user details to be fetched
      concatMap(() => this.actions$.pipe(ofType(userApiActions.readLoggedInUserSuccess))),
      concatMap(({ user: { language } }) => this.languageService.initAppLanguageForUser(language)),
      map(({ languageIso }) => languageEffectsActions.appLanguageInitForUser({ languageIso })),
      // unsubscribe when application language is initialized
      takeUntil(this.appLanguageInitialized$)
    );
  });

  readonly initAppLanguageForVisitor$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appInitFactoryActions.enter),
      // wait for fetch access token or initial accounts
      concatMap(() =>
        this.actions$.pipe(
          ofType(authApiActions.fetchAccessTokenFailure, authActions.loadAccountsAfterLoginFailed)
        )
      ),
      concatMap(() => this.languageService.initAppLanguageForVisitor()),
      map(({ languageIso }) => languageEffectsActions.appLanguageInitForVisitor({ languageIso })),
      // unsubscribe when application language is initialized
      takeUntil(this.appLanguageInitialized$)
    );
  });

  readonly switchAppLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appHeaderActions.switchAppLanguage),
      concatLatestFrom(() => this.store.select(selectLoggedInUser)),
      tap(([{ languageIso }]) => this.languageService.switchAppLanguage(languageIso)),
      tap(([{ languageIso }]) => this.zendeskService.setLocale(languageIso)),
      map(([{ languageIso }, user]) =>
        user
          ? languageEffectsActions.appLanguageSwitchedForUser({
              updateUser: {
                id: user.id,
                _version: user._version,
                language: LanguageMapper.toCode(languageIso),
              },
              notification: false,
              redirectUrl: false,
            })
          : languageEffectsActions.appLanguageSwitchedForVisitor()
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<State>,
    private readonly languageService: LanguageService,
    private readonly zendeskService: ZendeskService
  ) {}
}
