import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ActionState, Button, ButtonColor, ButtonSize } from '../../../elements';
import { createFeedbackClass } from '../feedback.config';

@Component({
  selector: 'ui-feedback-header',
  templateUrl: './feedback-header.component.html',
  styleUrl: './feedback-header.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackHeaderComponent {
  @Input()
  get state(): ActionState {
    return this._state;
  }
  set state(state: ActionState) {
    this.setHeader(state);
    this._state = state;
  }
  @Input() title!: string;
  @Input() icon: string | undefined;
  @Input() buttonText!: string;
  @Input() translationParams: Record<string, string> | undefined;
  @Input() showCloseBtn: boolean | undefined;
  @Input() isClarityMasked: boolean | undefined;

  @Output() handleAction = new EventEmitter();

  buttonConfig: Button | undefined;
  feedbackTypeClass!: string;

  private _state!: ActionState;

  setHeader(state: ActionState): void {
    this.feedbackTypeClass = createFeedbackClass(state);

    if (this.buttonText) {
      this.buttonConfig = {
        size: ButtonSize.Small,
        color: ButtonColor.Tertiary,
      };
    }
  }
}
