import {
  ProductOfferingBenefit,
  ProductOfferingFeature,
  ProductPlatformGroup,
  ProductPlatformOther,
} from '../models/product-offering-feature.model';

export interface ProductOfferingFeatureConfig {
  parent?: ProductOfferingFeature;
  description?: string;
  translation?: string;
  // Added to avoid concatenating the title of the column and the value of the cell
  upsellTranslation?: string;
  title?: string;
  order: number;
}

export type ProductOfferingFeatureDictionary = Record<
  ProductOfferingFeature,
  ProductOfferingFeatureConfig
>;

function createFeatureDictionary(dictionary: ProductOfferingFeatureDictionary): typeof dictionary {
  return dictionary;
}

let o = 0; // the definition of the dictionary will create the order.

export const featureMapping: ProductOfferingFeatureDictionary = createFeatureDictionary({
  // Platforms
  [ProductPlatformGroup.JobScout24]: {
    order: o++,
    description: 'PRODUCT_OFFERING.PLATFORM_JOBSCOUT',
  },
  [ProductPlatformGroup.Jobs]: {
    order: o++,
    description: 'PRODUCT_OFFERING.PLATFORM_JOBS',
  },
  [ProductPlatformGroup.JobUp]: {
    order: o++,
    description: 'PRODUCT_OFFERING.PLATFORM_JOBUP',
  },
  [ProductPlatformOther.JobWinner]: {
    order: o++,
    description: 'PRODUCT_OFFERING.PLATFORM_JOBWINNER',
  },
  [ProductPlatformOther.TopJobsAlpha]: {
    order: o++,
    description: 'PRODUCT_OFFERING.PLATFORM_TOPJOBS_AND_ALPHA',
  }, // Benefits
  [ProductOfferingBenefit.Calendar]: {
    order: o++,
    title: 'PRODUCT_OFFERING.FEATURES.ONLINE',
    translation: 'PRODUCT_OFFERING.JOBSCOUT24.FEATURE_CALENDAR',
    upsellTranslation: 'PRODUCT_OFFERING_UPSELL.JOBSCOUT24.FEATURE_CALENDAR',
  },
  [ProductOfferingBenefit.Calendar30]: {
    order: o++,
    title: 'PRODUCT_OFFERING.FEATURES.ONLINE',
    translation: 'PRODUCT_OFFERING.FEATURE_CALENDAR_30',
  },
  [ProductOfferingBenefit.Calendar31Limited]: {
    order: o++,
    title: 'PRODUCT_OFFERING.FEATURES.ONLINE',
    translation: 'PRODUCT_OFFERING.JOBSCOUT24.FEATURE_CALENDAR',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_CALENDAR_31_LIMITED.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.Calendar7Jobs]: {
    parent: ProductOfferingBenefit.Calendar,
    order: o++,
    title: 'PRODUCT_OFFERING.FEATURES.ONLINE',
    translation: 'PRODUCT_OPTIONS.PRODUCT.FEATURE_CALENDAR_7',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE.MODAL.DESCRIPTION_JOBS',
    upsellTranslation: 'PRODUCT_OFFERING_UPSELL.JOBS.FEATURE_CALENDAR_7',
  },
  [ProductOfferingBenefit.Calendar7Jobup]: {
    parent: ProductOfferingBenefit.Calendar,
    order: o++,
    title: 'PRODUCT_OFFERING.FEATURES.ONLINE',
    translation: 'PRODUCT_OPTIONS.PRODUCT.FEATURE_CALENDAR_7',
    description: 'PRODUCT_OFFERING.JOBUP.FEATURE_ONLINE.MODAL.DESCRIPTION',
    upsellTranslation: 'PRODUCT_OFFERING_UPSELL.JOBS.FEATURE_CALENDAR_7',
  },
  [ProductOfferingBenefit.Calendar7Plus]: {
    parent: ProductOfferingBenefit.Calendar,
    order: o++,
    title: 'PRODUCT_OFFERING.FEATURES.ONLINE',
    translation: 'PRODUCT_OPTIONS.PRODUCT.FEATURE_CALENDAR_7_PLUS',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE.MODAL.DESCRIPTION',
    upsellTranslation: 'PRODUCT_OFFERING_UPSELL.JOBS.FEATURE_CALENDAR_7_PLUS',
  },
  [ProductOfferingBenefit.Calendar45]: {
    parent: ProductOfferingBenefit.Calendar,
    order: o++,
    title: 'PRODUCT_OFFERING.FEATURES.ONLINE',
    translation: 'PRODUCT_OFFERING.FEATURE_CALENDAR_45',
  },
  [ProductOfferingBenefit.Calendar60]: {
    parent: ProductOfferingBenefit.Calendar,
    order: o++,
    title: 'PRODUCT_OFFERING.FEATURES.ONLINE',
    translation: 'PRODUCT_OFFERING.FEATURE_CALENDAR_60',
  },
  [ProductOfferingBenefit.OnlineATS]: {
    order: o++,
    title: 'PRODUCT_OFFERING.JOBSCOUT24.FEATURE_ONLINE_ATS',
    description: 'PRODUCT_OFFERING.JOBSCOUT24.FEATURE_ONLINE_ATS.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.InboxIn]: {
    order: o++,
    title: 'PRODUCT_OFFERING.JOBSCOUT24.FEATURE_INBOX_IN',
    description: 'PRODUCT_OFFERING.JOBSCOUT24.FEATURE_INBOX_IN.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.GoogleJobPosting]: {
    order: o++,
    title: 'PRODUCT_OFFERING.JOBSCOUT24.FEATURE_GOOGLE_JOB_POSTING',
    description: 'PRODUCT_OFFERING.JOBSCOUT24.FEATURE_GOOGLE_JOB_POSTING.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.Performance]: {
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_PERFORMANCE_PLUS',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_PERFORMANCE_PLUS.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.JobAlert]: {
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.JobAlert1x]: {
    parent: ProductOfferingBenefit.JobAlert,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.1X.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.1X',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.MODAL.DESCRIPTION',
    upsellTranslation: 'PRODUCT_OFFERING_UPSELL.JOBS.FEATURE_JOB_ALERT_1X',
  },
  [ProductOfferingBenefit.JobAlert3x]: {
    parent: ProductOfferingBenefit.JobAlert,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.3X.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.3X',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.JobAlert5x]: {
    parent: ProductOfferingBenefit.JobAlert,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.5X.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.5X',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.JobAlert9x]: {
    parent: ProductOfferingBenefit.JobAlert,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.9X.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.9X',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.JobAlert11x]: {
    parent: ProductOfferingBenefit.JobAlert,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.11X.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.11X',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_ALERT.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.JobBoost]: {
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.JobBoost2x]: {
    parent: ProductOfferingBenefit.JobBoost,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.2X.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.2X',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.JobBoost4x]: {
    parent: ProductOfferingBenefit.JobBoost,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.4X.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.4X',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.JobBoost8x]: {
    parent: ProductOfferingBenefit.JobBoost,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.8X.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.8X',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.JobBoost10x]: {
    parent: ProductOfferingBenefit.JobBoost,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.10X.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.10X',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_JOB_BOOST.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.OnlineCampaigns]: {
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE_CAMPAIGNS',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE_CAMPAIGNS.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.OnlineCampaignsCheckmark]: {
    parent: ProductOfferingBenefit.OnlineCampaigns,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE_CAMPAIGNS.CHECKMARK.MODAL.TITLE',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE_CAMPAIGNS.CHECKMARK.MODAL.DESCRIPTION',
    upsellTranslation: 'PRODUCT_OFFERING_UPSELL.JOBS.FEATURE_ONLINE_CAMPAIGNS.CHECKMARK',
  },
  [ProductOfferingBenefit.OnlineCampaignsBoost]: {
    parent: ProductOfferingBenefit.OnlineCampaigns,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE_CAMPAIGNS.BOOST.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE_CAMPAIGNS.BOOST',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE_CAMPAIGNS.BOOST.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.OnlineCampaignsPlus]: {
    parent: ProductOfferingBenefit.OnlineCampaigns,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE_CAMPAIGNS.PLUS.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE_CAMPAIGNS.PLUS',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE_CAMPAIGNS.PLUS.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.PartnerNetwork]: {
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_PARTNER_NETWORK',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_PARTNER_NETWORK.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.PartnerNetworkCheckmark]: {
    parent: ProductOfferingBenefit.PartnerNetwork,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_PARTNER_NETWORK.CHECKMARK.MODAL.TITLE',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_PARTNER_NETWORK.CHECKMARK.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.PartnerNetworkBoost]: {
    parent: ProductOfferingBenefit.PartnerNetwork,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_PARTNER_NETWORK.BOOST.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_PARTNER_NETWORK.BOOST',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_PARTNER_NETWORK.BOOST.MODAL.DESCRIPTION',
  },
  [ProductOfferingBenefit.PartnerNetworkPlus]: {
    parent: ProductOfferingBenefit.PartnerNetwork,
    order: o++,
    title: 'PRODUCT_OFFERING.JOBS.FEATURE_PARTNER_NETWORK.PLUS.MODAL.TITLE',
    translation: 'PRODUCT_OFFERING.JOBS.FEATURE_PARTNER_NETWORK.PLUS',
    description: 'PRODUCT_OFFERING.JOBS.FEATURE_PARTNER_NETWORK.PLUS.MODAL.DESCRIPTION',
  },
});

export const JOBS_FEATURE_FOR_FREE_TRANSLATION = 'PRODUCT_OFFERING.PLATFORM_JOBS.FREE';
export const CALENDAR7_FEATURE_TRANSLATION: ProductOfferingFeatureConfig = {
  order: 1,
  title: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE',
  description: 'PRODUCT_OFFERING.JOBS.FEATURE_ONLINE.MODAL.DESCRIPTION',
};
export const CALENDAR7_JOBUP_FEATURE_TRANSLATION: ProductOfferingFeatureConfig = {
  order: 1,
  title: 'PRODUCT_OFFERING.JOBUP.FEATURE_ONLINE',
  description: 'PRODUCT_OFFERING.JOBUP.FEATURE_ONLINE.MODAL.DESCRIPTION',
};
export const CALENDAR7_FEATURE_COMPARE_TABLE_TRANSLATION = CALENDAR7_FEATURE_TRANSLATION.title;
export const CALENDAR7_JOBUP_FEATURE_COMPARE_TABLE_TRANSLATION =
  CALENDAR7_JOBUP_FEATURE_TRANSLATION.title;
