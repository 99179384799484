import { Injectable } from '@angular/core';
import { CompanyProfile } from '@company/models/company-profile.model';
import { EMPLOYEE_COUNT_RANGE } from '@config/metadatas/constants';

@Injectable({ providedIn: 'root' })
export class CompanyProfileSerializer {
  fromJson(json: any): CompanyProfile {
    const ecf = mapEmployeeCount(json.employeeCountFrom, 'from');
    const ect = mapEmployeeCount(json.employeeCountTo, 'to');

    return {
      id: json.id,
      name: json.name,
      links: json.links,
      benefits: json.benefits,
      description: json.description,
      legalEntityId: json.legalEntityId,
      legalEntityName: json.legalEntityName,
      legalEntityAddress: json.legalEntityAddress,
      legalEntityState: json.legalEntityState,
      legalEntityVersion: json.legalEntityVersion,
      logoMediaApiId: json.logoMediaApiId,
      headerMediaApiId: json.headerMediaApiId,
      employeeCountFrom: ecf,
      employeeCountTo: ect,
      companySize: `${ecf}-${ect}`,
      _embedded: json._embedded,
      locations: json.locations,
      videos: json.videos.map(({ __typename, ...rest }) => rest),
      _version: json._version,
      _links: json._links,
    };
  }

  toJson(companyProfile: CompanyProfile): any {
    const ecf = mapEmployeeCount(companyProfile.employeeCountFrom, 'from');
    const ect = mapEmployeeCount(companyProfile.employeeCountTo, 'to');

    return {
      id: companyProfile.id,
      name: companyProfile.name,
      links: companyProfile.links,
      benefits: companyProfile.benefits,
      description: companyProfile.description,
      logoMediaApiId: companyProfile.logoMediaApiId,
      headerMediaApiId: companyProfile.headerMediaApiId,
      legalEntityId: companyProfile.legalEntityId,
      legalEntityName: companyProfile.legalEntityName,
      legalEntityAddress: companyProfile.legalEntityAddress,
      legalEntityState: companyProfile.legalEntityState,
      legalEntityVersion: companyProfile.legalEntityVersion,
      employeeCountFrom: ecf,
      employeeCountTo: ect,
      _embedded: companyProfile._embedded,
      locations: companyProfile.locations,
      videos: companyProfile.videos.map(({ __typename, ...rest }) => rest),
      _version: companyProfile._version,
    };
  }
}

function rangeEmployeeCount(val: number, type: string) {
  let results = EMPLOYEE_COUNT_RANGE.find((r) => r.min <= val && r.max >= val);

  if (results === undefined) {
    results = EMPLOYEE_COUNT_RANGE[EMPLOYEE_COUNT_RANGE.length - 1];
  }

  return type === 'from' ? results.min : results.max;
}

function mapEmployeeCount(val: number, type: string) {
  if (Number(val)) {
    return rangeEmployeeCount(val, type);
  } else {
    return type === 'from' ? EMPLOYEE_COUNT_RANGE[0].min : EMPLOYEE_COUNT_RANGE[0].max;
  }
}
