import { inject } from '@angular/core';
import { appInitFactoryActions } from '@app/store/actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, tap } from 'rxjs';
import { ZendeskService } from '../../../../zendesk/util/src';
import { selectShowIntercom } from './debug.selectors';

// todo: remove once feature flag is removed
export const initZendesk = createEffect(
  (actions$ = inject(Actions), store = inject(Store), zendeskService = inject(ZendeskService)) =>
    actions$.pipe(
      ofType(appInitFactoryActions.enter),
      delay(1000),
      concatLatestFrom(() => store.select(selectShowIntercom)),
      tap(([, showIntercom]) => {
        if (!showIntercom) {
          zendeskService.showWidget();
        } else {
          zendeskService.hideWidget();
        }
      })
    ),
  { functional: true, dispatch: false }
);
