import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as DebugEffects from './debug.effects';
import { debugFeature } from './debug.reducer';

@NgModule({
  imports: [StoreModule.forFeature(debugFeature), EffectsModule.forFeature([DebugEffects])],
})
export class DebugStateModule {}
