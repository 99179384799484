<div class="wrapper">
  <div class="dialog-title" mat-dialog-title>
    <div
      class="product-best-value"
      [class.product-best-value-background]="isBestValue"
      [class]="platform + '-background'"
    >
      {{ isBestValue ? (bestValueTranslation | translate) : '' }}
    </div>
    <div class="product-details-header">
      <span class="title-s">{{ product.name | translate }}</span>
      <mat-icon (click)="close(false)" mkpDataTest="benefits-close">close</mat-icon>
    </div>
  </div>

  <section mat-dialog-content>
    <div class="content-wrapper">
      <div class="calendar-feature-wrapper">
        <mkp-product-calendar-label
          *ngIf="calendarFeature"
          [productCode]="product.code"
          [title]="calendarFeature.translation"
        />
        <mkp-product-tooltip
          *ngIf="calendarFeature?.description"
          [text]="calendarFeature.description"
        />
      </div>
      <hr />
      <div class="platforms-wrapper" *ngIf="platformMapping?.length">
        <span class="body-s semibold title">{{ 'PRODUCT_OFFERING.PLATFORMS' | translate }}</span>
        <div *ngFor="let platform of platformMapping" class="platform">
          <mkp-product-platform-cell [platform]="platform.id" />
          <span
            class="body-s platform-description description"
            [innerHtml]="platform.description | translate"
          ></span>
        </div>
      </div>
      <div class="benefits-wrapper" *ngIf="benefitMapping?.length">
        <span class="body-s semibold title">{{ 'PRODUCT_OFFERING.BENEFITS' | translate }}</span>
        <div class="benefit" *ngFor="let benefit of benefitMapping">
          <span class="body-default bold">{{ benefit.title | translate }}</span>
          <span class="body-s description">{{ benefit.description | translate }}</span>
        </div>
      </div>

      <div
        *ngIf="
          product.code === ProductCode.JOBSOFFER22XS || product.code === ProductCode.JOBUP24LITE
        "
        class="subcategories-wrapper"
      >
        <hr />
        <div class="title-xxs title-benefits-m-b">
          @if (product.code === ProductCode.JOBSOFFER22XS) {
            {{ 'PRODUCT_OFFERING.JOBS.LITE_PRODUCT.SUBCATEGORIES.HEADING' | translate }}
          } @else {
            {{ 'PRODUCT_OFFERING.JOBUP.LITE_PRODUCT.ITEMS.HEADING' | translate }}
          }
        </div>
        @if (product.code === ProductCode.JOBSOFFER22XS) {
          @for (category of LITE_PRODUCT_AVAILABLE_SUBCATEGORIES; track category.name) {
            <div class="sub-category">
              <span class="body-default bold">{{ category.name | translate }}</span>
              <ul>
                @for (item of category.items; track item) {
                  <li>
                    {{ item | translate }}
                  </li>
                }
              </ul>
            </div>
          }
        } @else {
          <div class="sub-category">
            <ul>
              @for (item of JOBUP_LITE_PRODUCT_AVAILABLE_ITEMS; track item) {
                <li>
                  {{ item | translate }}
                </li>
              }
            </ul>
          </div>
        }
      </div>
    </div>
  </section>
  <section mat-dialog-actions *ngIf="!isReadMode">
    <ui-button
      class="action-btn"
      [config]="selectConfig"
      (click)="close(true)"
      mkpDataTest="select-cta-detailed-view-btn"
    >
      {{ 'PRODUCT_OFFERING.SELECT_PLAN' | translate }}
    </ui-button>
  </section>
</div>
