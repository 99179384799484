import { AccountDto } from '../account/account.dto';
import { LegalEntityService } from '@company/services/legal-entity.service';
import { map, Observable, OperatorFunction, pipe, switchMap, take } from 'rxjs';
import { Account } from '../account/account.model';
import { LegalEntity } from '../legal-entity/legal-entity.model';
import { LegalEntityDto } from '../legal-entity/legal-entity.dto';
import { mapAccountDtoToAccount } from '../account/account.mapper';
import { mapLegalEntityDtoToLegalEntity } from '../legal-entity/legal-entity.mapper';

export const addLegalEntityToAccountDto = (
  legalEntityService: LegalEntityService
): OperatorFunction<AccountDto, Account> =>
  pipe(
    switchMap((account: AccountDto) =>
      fetchLegalEntitiesAndMapToAccount([account], legalEntityService)
    ),
    map((accounts) => accounts[0])
  );

export const addLegalEntityToAccountsDto = (
  legalEntityService: LegalEntityService
): OperatorFunction<AccountDto[], Account[]> =>
  pipe(
    switchMap((accounts: AccountDto[]) =>
      fetchLegalEntitiesAndMapToAccount(accounts, legalEntityService)
    )
  );

const fetchLegalEntitiesAndMapToAccount = (
  accounts: AccountDto[],
  legalEntityService: LegalEntityService
): Observable<Account[]> =>
  legalEntityService.list({ filter: getLegalEntityFilter(accounts) }).pipe(
    map(({ _embedded }) => _embedded?.results ?? []),
    map(mapLegalEntityDtosToLegalEntities),
    map((legalEntities) => mapAccountDtosToAccounts(accounts, legalEntities)),
    take(1)
  );

const getLegalEntityFilter = (accounts: AccountDto[]): string =>
  accounts.map((account) => `id==${account.legalEntityId}`).join(',');

const mapAccountDtosToAccounts = (
  accounts: AccountDto[],
  legalEntities: LegalEntity[]
): Account[] => {
  const legalEntityMap = new Map(legalEntities.map((legalEntity) => [legalEntity.id, legalEntity]));
  return accounts.map((account) =>
    mapAccountDtoToAccount(account, legalEntityMap.get(account.legalEntityId) as LegalEntity)
  );
};

// created this ugly casting because resource service list method is mistakenly typed
// to return LegalEntity[] instead of LegalEntityDto[]
const mapLegalEntityDtosToLegalEntities = (dtos: any[]) =>
  dtos.map((dto: any) => mapLegalEntityDtoToLegalEntity(dto as LegalEntityDto));
