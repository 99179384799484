import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BaseHttpResource, CoreListEnvelop, QueryParams } from '@mkp/shared/data-access';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { Application, ApplicationDto } from './application.dto';
import { mapApplicationDtoToModel } from './application.mapper';

export interface FetchApplicationPayload {
  applicationStatusId: string;
  vacancyId: string;
  offset: number;
  limit: number;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationResource extends BaseHttpResource<
  ApplicationDto,
  CoreListEnvelop<ApplicationDto>,
  Application
> {
  protected override readonly apiUrl = environment.api.internalATS;

  constructor() {
    super('application');
  }

  fetchApplicationsByStatus({
    applicationStatusId,
    vacancyId,
    offset,
    limit,
  }: FetchApplicationPayload): Observable<Application[]> {
    const params: QueryParams = {
      filter: getFilter({ applicationStatusId, vacancyId }),
      offset,
      limit,
    };

    return this.http
      .get<CoreListEnvelop<ApplicationDto>>(getApplicationUrl(this.uri), { params })
      .pipe(
        map(({ _embedded: { results: applicationDtos } }) =>
          applicationDtos.map(mapApplicationDtoToModel)
        )
      );
  }

  getCountByApplicationStatusId(
    applicationStatusId: string,
    vacancyId: string
  ): Observable<number> {
    const params: QueryParams = {
      filter: getFilter({ applicationStatusId, vacancyId }),
      offset: 0,
      limit: 0,
    };

    return this.http
      .get<CoreListEnvelop<Application>>(getApplicationUrl(this.uri), { params })
      .pipe(
        map((applicationsResponse) => applicationsResponse.totalCount),
        catchError(() => of(0))
      );
  }

  deleteApplication(applicationId: string): Observable<void> {
    return this.http.delete<void>(`${getApplicationUrl(this.uri)}/${applicationId}`);
  }

  private getApplicationCount(vacancyId: string): Observable<number> {
    return this.getWithQuery({
      filter: `vacancy.id==${vacancyId}`,
      limit: 0,
    }).pipe(map(({ totalCount }) => totalCount));
  }

  getApplicationCounts(vacancyIds: string[]): Observable<Record<string, number>> {
    return forkJoin(
      vacancyIds.reduce(
        (acc, vacancyId) => ({ ...acc, [vacancyId]: this.getApplicationCount(vacancyId) }),
        {}
      )
    );
  }

  checkApplicationsPresence(vacancyId: string): Observable<boolean> {
    return this.getApplicationCount(vacancyId).pipe(map((totalNumber) => totalNumber > 0));
  }

  // TODO: Temporary used _version here.
  updateApplicationStatus(
    applicationId: string,
    applicationStatusId: string,
    _version: string
  ): Observable<Application> {
    return this.http.patch<Application>(`${getApplicationUrl(this.uri)}/${applicationId}`, {
      applicationStatusId,
      _version,
    });
  }

  getTotalCountForVacancies(vacancyIds: string[]): Observable<number> {
    return this.getWithQuery({ filter: getVacanciesFilter(vacancyIds), limit: 0 }).pipe(
      map(({ totalCount }) => totalCount)
    );
  }
}

const getApplicationUrl = (uri: string): string => `${environment.api.internalATS}/${uri}`;
const getFilter = ({
  applicationStatusId,
  vacancyId,
}: Pick<FetchApplicationPayload, 'applicationStatusId' | 'vacancyId'>): string =>
  `applicationStatus.id==${applicationStatusId};vacancy.id==${vacancyId}`;
const getVacanciesFilter = (vacancyIds: string[]): string =>
  vacancyIds.map((vacancyId) => `vacancy.id==${vacancyId}`).join(',');
