import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Button, ButtonColor, ButtonComponent, ButtonSize } from '@mkp/shared/ui-library';
import {
  BEST_VALUE_PRODUCTS,
  CALENDAR7_FEATURE_TRANSLATION,
  CALENDAR7_JOBUP_FEATURE_TRANSLATION,
  JOBUP_LITE_PRODUCT_AVAILABLE_ITEMS,
  LITE_PRODUCT_AVAILABLE_SUBCATEGORIES,
  Product,
  ProductCode,
  ProductFeature,
  ProductOfferingBenefit,
  ProductOfferingFeature,
  ProductOfferingPlatform,
  ProductPlatformGroup,
} from '@mkp/shared/util-product';

import { CurrencyPipe, NgForOf, NgIf } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {
  ProductCalendarLabelComponent,
  ProductFeatureCellComponent,
  ProductPlatformCellComponent,
  ProductTooltipComponent,
} from '@mkp/product/feature-product-shared';
import { DataTestDirective } from '@mkp/tracking/feature-tracking';
import { TranslateModule } from '@ngx-translate/core';

export type ProductOfferingModalType = {
  product: Product;
  benefits: ProductOfferingBenefit[];
  platforms: ProductOfferingPlatform[];
  isReadMode: boolean;
  bestValueTranslation: string;
  platform: ProductPlatformGroup;
};

@Component({
  selector: 'mkp-explain-benefits',
  templateUrl: './explain-benefits.component.html',
  styleUrl: './explain-benefits.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    NgIf,
    NgForOf,
    ButtonComponent,
    MatDialogModule,
    DataTestDirective,
    TranslateModule,
    CurrencyPipe,
    ProductTooltipComponent,
    ProductCalendarLabelComponent,
    ProductFeatureCellComponent,
    ProductPlatformCellComponent,
  ],
})
export class ExplainBenefitsComponent implements OnInit {
  product: Product;
  benefits: ProductOfferingBenefit[];
  platforms: ProductOfferingPlatform[];
  isReadMode = false;
  bestValueTranslation: string;

  selectConfig: Button = {
    size: ButtonSize.Medium,
    color: ButtonColor.Primary,
  };
  isBestValue = false;
  platformMapping: ProductFeature[] = [];
  benefitMapping: ProductFeature[] = [];
  calendarFeature: ProductFeature | undefined;
  ProductCode = ProductCode;
  platform: ProductPlatformGroup;
  LITE_PRODUCT_AVAILABLE_SUBCATEGORIES = LITE_PRODUCT_AVAILABLE_SUBCATEGORIES;
  JOBUP_LITE_PRODUCT_AVAILABLE_ITEMS = JOBUP_LITE_PRODUCT_AVAILABLE_ITEMS;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ProductOfferingModalType,
    private dialogRef: MatDialogRef<ExplainBenefitsComponent>
  ) {
    this.product = data.product;
    this.benefits = data.benefits;
    this.platforms = data.platforms;
    this.isReadMode = data.isReadMode;
    this.bestValueTranslation = data.bestValueTranslation;
    this.platform = data.platform;
  }

  ngOnInit(): void {
    this.isBestValue = BEST_VALUE_PRODUCTS.includes(this.product.code as ProductCode);
    this.calendarFeature = this.product.features.find(({ id }) => id.includes('calendar'));

    this.setFeatureMapping();
  }

  close(isSelected: boolean): void {
    this.dialogRef.close(isSelected);
  }

  private setFeatureMapping() {
    if (this.benefits?.length && this.product) {
      this.benefitMapping = this.setFeatures(this.benefits);

      // adding calender feature only for XS product (7 + 24 days online) and jobup lite (7 + 23 days online)
      if (
        this.product.code === ProductCode.JOBSOFFER22XS ||
        this.product.code === ProductCode.JOBUP24LITE
      ) {
        this.benefitMapping.unshift(this.calenderFeatureForSelect(this.product.code));
      }
    }
    if (this.platforms?.length && this.product) {
      this.platformMapping = this.setFeatures(this.platforms);
    }
  }

  private calenderFeatureForSelect(
    productCode: ProductCode.JOBSOFFER22XS | ProductCode.JOBUP24LITE
  ): ProductFeature {
    if (productCode === ProductCode.JOBSOFFER22XS) {
      return {
        id: ProductOfferingBenefit.Calendar7Jobs,
        title: CALENDAR7_FEATURE_TRANSLATION.title,
        description: CALENDAR7_FEATURE_TRANSLATION.description,
      };
    } else {
      return {
        id: ProductOfferingBenefit.Calendar7Jobup,
        title: CALENDAR7_JOBUP_FEATURE_TRANSLATION.title,
        description: CALENDAR7_JOBUP_FEATURE_TRANSLATION.description,
      };
    }
  }

  private setFeatures(features: ProductOfferingFeature[]): ProductFeature[] {
    return features.reduce((features: ProductFeature[], productFeature: ProductOfferingFeature) => {
      const feature = this.product.features.find((feature) =>
        [feature.parent, feature.id].includes(productFeature)
      );

      if (feature) features = [...features, feature];

      return features;
    }, []);
  }
}
