import { MinMax } from '../models';

export const EMPLOYEE_COUNT_RANGE: MinMax[] = [
  { min: 1, max: 10 },
  { min: 11, max: 20 },
  { min: 21, max: 50 },
  { min: 51, max: 100 },
  { min: 101, max: 250 },
  { min: 251, max: 500 },
  { min: 501, max: 1_000 },
  { min: 1_001, max: 200_000 }, // Update 
];
