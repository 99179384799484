import { Injectable } from '@angular/core';
import { AuthFacade } from '@mkp/auth/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as appInitFactoryActions from '@store/actions/app-init-factory.actions';
import { authApiActions } from '@mkp/auth/actions';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthApiEffects {
  readonly fetchAccessToken$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(appInitFactoryActions.enter),
        switchMap(() =>
          this.authFacade.fetchAccessToken().pipe(
            map(({ actorId, intercomHash, zendeskJWT }) =>
              authApiActions.fetchAccessTokenSuccess({ actorId, intercomHash, zendeskJWT })
            ),
            catchError((error: unknown) => of(authApiActions.fetchAccessTokenFailure({ error })))
          )
        )
      );
    },
    { useEffectsErrorHandler: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authFacade: AuthFacade
  ) {}
}
