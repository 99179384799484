// zendesk.service.ts
import { inject, Injectable } from '@angular/core';
import { LanguageIso } from '@mkp/shared/data-access';
import { WindowWithZendesk } from './zendesk.model';

@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  private readonly window = inject(Window) as WindowWithZendesk;

  private getZendesk() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return this.window.zE || (() => {});
  }

  showWidget() {
    this.getZendesk()('messenger', 'show');
  }

  hideWidget() {
    this.getZendesk()('messenger', 'hide');
  }

  openWidget() {
    this.getZendesk()('messenger', 'open');
  }

  closeWidget() {
    this.getZendesk()('messenger', 'close');
  }

  // see locales https://support.zendesk.com/api/v2/locales/public.json
  setLocale(locale: LanguageIso) {
    this.getZendesk()('messenger:set', 'locale', mapLanguageIsoToZendeskLocale(locale));
  }

  login(zendeskJWT: string) {
    this.getZendesk()('messenger', 'loginUser', function (callback: any) {
      callback(zendeskJWT);
    });
  }
}

function mapLanguageIsoToZendeskLocale(languageIso: LanguageIso) {
  switch (languageIso) {
    case LanguageIso.GERMAN:
      return 'de';
    case LanguageIso.FRENCH:
      return 'fr';
    case LanguageIso.ENGLISH:
      return 'en-US';
    default:
      return 'en-US';
  }
}
